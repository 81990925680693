import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import scorePJService from "../services/scorePJService"; // Importe o serviço atualizado

const initialState = {
  presCobPJ: [],
  scoreQuodPJ: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getPresCobPJ = createAsyncThunk(
  "scorepj/getprescobpj",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await scorePJService.getPresCobPJ(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getScoreQuod = createAsyncThunk(
  "scorepj/getscorequod",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await scorePJService.getScoreQuod(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const scorePJSlice = createSlice({
  name: "scorepj",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresCobPJ.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getPresCobPJ.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.presCobPJ = action.payload;
      })
      .addCase(getPresCobPJ.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getScoreQuod.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getScoreQuod.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.scoreQuodPJ = action.payload;
      })
      .addCase(getScoreQuod.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = scorePJSlice.actions;
export default scorePJSlice.reducer;
