import { configureStore } from "@reduxjs/toolkit"

import curvaReducer from './slices/curvaSlice'
import dashboardReducer from './slices/dashboardSlice'
import dadosEssenciaisPFReducer from './slices/dadosEssenciaisPFSlice'
import dadosEssenciaisPJReducer from './slices/dadosEssenciaisPJSlice'
import scorePFReducer from './slices/scorePFSlice'
import scorePJReducer from './slices/scorePJSlice'
import processosPFReducer from './slices/processosPFSlice'
import processosPJReducer from './slices/processosPJSlice'
import iaPFReducer from './slices/iaPFSlice'
import iaPJReducer from './slices/iaPJSlice'
import protestosPJReducer from './slices/protestosPJSlice'
import cceeReducer from './slices/cceeSlice'

export const store = configureStore({
  reducer: {
    curva: curvaReducer,
    dashboard: dashboardReducer,
    dadosEssenciaisPF: dadosEssenciaisPFReducer,
    dadosEssenciaisPJ: dadosEssenciaisPJReducer,
    scorePF: scorePFReducer,
    scorePJ: scorePJReducer,
    processosPF: processosPFReducer,
    processosPJ: processosPJReducer,
    iaPF: iaPFReducer,
    iaPJ: iaPJReducer,
    protestosPJ: protestosPJReducer,
    ccee: cceeReducer,
  },
})