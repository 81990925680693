import { api, requestConfig } from "../utils/config";

const getDadosBasicosEmpresa = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dados_basicos_empresa/${cnpj}`);
    const res = await fetch(api + `/dados_basicos_empresa/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getDadosBasicosEmpresaHistorico = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dados_basicos_empresa_historico/${cnpj}`);
    const res = await fetch(api + `/dados_basicos_empresa_historico/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getEvolucaoEmpresa = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/evolucao_empresa/${cnpj}`);
    const res = await fetch(api + `/evolucao_empresa/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getDividas = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/government_debtors/${cnpj}`);
    const res = await fetch(api + `/government_debtors/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getAtividades = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/activity_indicators/${cnpj}`);
    const res = await fetch(api + `/activity_indicators/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getRelacoes = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/relacoes/${cnpj}`);
    const res = await fetch(api + `/relacoes/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const dadosEssenciaisPJService = {
  getDividas,
  getAtividades,
  getDadosBasicosEmpresa,
  getDadosBasicosEmpresaHistorico,
  getEvolucaoEmpresa,
  getRelacoes
};

export default dadosEssenciaisPJService;