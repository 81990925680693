import React, { useState } from "react";
import FiltroAgentes from "../../components/Agentes/FiltroAgentes";
import AgentesChart from "../../components/Agentes/AgentesChart";

const Agentes = () => {
  const [nome_empresarial, setNomeEmpresarial] = useState("")
  const [classe_perfil_agente, setClassePerfilAgente] = useState("")
  
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 gap-5">
        <h1 className="text-3xl font-bold text-white">Agentes SEB</h1>
        <div className="flex flex-wrap col-span-2 h-20 w-full bg-blue-300 rounded-lg items-center">
          <FiltroAgentes nome_empresarial={nome_empresarial} setNomeEmpresarial={setNomeEmpresarial} classe_perfil_agente={classe_perfil_agente} setClassePerfilAgente={setClassePerfilAgente} />
        </div>
        <div className="col-span-2 bg-blue-300 rounded-lg">
          <AgentesChart nome_empresarial={nome_empresarial} classe_perfil_agente={classe_perfil_agente} />
        </div>
      </div>
    </div>
  );
};

export default Agentes;
