import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCCEENome } from "../../slices/cceeSlice"; 
import Select from "react-select";
import { TailSpin } from "react-loader-spinner"; // Importe o TailSpin

const classesFinal = [
  "Distribuidor", "Produtor Independente", "Comercializador", "Exportador", "Gerador",
  "Consumidor Livre", "Consumidor Especial", "Transmissor", "Autoprodutor"
].sort(); // Ordenar a lista em ordem crescente

const FiltroAgentes = ({
  nome_empresarial,
  setNomeEmpresarial,
  classe_perfil_agente,
  setClassePerfilAgente
}) => {
  const dispatch = useDispatch();
  const { cceeNomes } = useSelector((state) => state.ccee);
  const [opcoesEmpresas, setOpcoesEmpresas] = useState([]);
  const [opcoesClasses, setOpcoesClasses] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carregamento

  // Atualizar as opções de classes
  useEffect(() => {
    const opcoes = classesFinal.map(classe_perfil_agente => ({
      label: classe_perfil_agente,
      value: classe_perfil_agente
    }));
    setOpcoesClasses(opcoes);
  }, []);

  // Carregar os nomes empresariais após selecionar a classe
  useEffect(() => {
    if (classe_perfil_agente) {
      setLoading(true); // Inicia o carregamento
      dispatch(getCCEENome({classe_perfil_agente}))
        .finally(() => setLoading(false)); // Finaliza o carregamento
    }
  }, [classe_perfil_agente, dispatch]);

  // Atualizar as opções de nomes empresariais quando o estado cceeNomes mudar
  useEffect(() => {
    if (cceeNomes && cceeNomes.length > 0) {
      const nomesDistintos = [...new Set(cceeNomes.map(item => item.NOME_EMPRESARIAL))];
      nomesDistintos.sort(); // Ordenar os nomes empresariais
      const opcoes = nomesDistintos.map(nome_empresarial => ({
        label: nome_empresarial,
        value: nome_empresarial
      }));
      setOpcoesEmpresas(opcoes);
    }
  }, [cceeNomes]);

  // Manipular a seleção de classe e disparar o filtro de nomes
  const handleSelecionarClasse = (selectedOption) => {
    if (selectedOption) {
      setClassePerfilAgente(selectedOption.value);
    } else {
      setClassePerfilAgente("");
    }
  };

  // Manipular a seleção de nome empresarial
  const handleSelecionarNome = (selectedOption) => {
    if (selectedOption) {
      setNomeEmpresarial(selectedOption.value);
    } else {
      setNomeEmpresarial("");
    }
  };

  return (
    <div className="flex flex-col gap-x-5">
      <div className="flex pl-5">
        <div className="flex flex-col pl-5">
          <span className="filtro">Escolha a Classe de Perfil:</span>
          <Select
            className="rounded-lg"
            value={opcoesClasses.find(option => option.value === classe_perfil_agente)}
            onChange={handleSelecionarClasse}
            options={opcoesClasses}
            isClearable
            placeholder="Selecione a classe..."
          />
        </div>

        <div className="flex flex-col pl-5">
          <span className="filtro">Escolha a Empresa:</span>
          {loading ? (
            <div className="flex justify-center items-center">
              <TailSpin color="#00BFFF" height={80} width={80} />
              <p className="text-black ml-4">Carregando dados...</p>
            </div>
          ) : (
            <Select
              className="rounded-lg z-1001"
              value={opcoesEmpresas.find(option => option.value === nome_empresarial)}
              onChange={handleSelecionarNome}
              options={opcoesEmpresas}
              isClearable
              placeholder="Digite ou selecione a empresa..."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FiltroAgentes;
