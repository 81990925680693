import { api, requestConfig } from "../utils/config";

// Obtenha todas as curvas
const getAllCurvaForward = async () => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    const res = await fetch(api + "/all_curva_fwd", config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const curvaService = {
  getAllCurvaForward
};

export default curvaService;