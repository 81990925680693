import React, { useState } from "react";
import Filtro1Dashboard from "../../components/Dashboard/Filtro1Dashboard";
import DashboardChart from "../../components/Dashboard/DashboardChart";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import Filtro2Dashboard from "../../components/Dashboard/Filtro2Dashboard";

const Dashboard = () => {
  const [score, setScore] = useState("");
  const [mtm, setMtm] = useState("");
  const [mwh, setMwh] = useState(true);
  const [menorY1, setMenorY1] = useState("");
  const [maiorY1, setMaiorY1] = useState("");
  const [total, setTotal] = useState(true);
  const [dataSelecionada, setDataSelecionada] = useState("");
  const [dataSelecionada2, setDataSelecionada2] = useState("");
  const [scoreNumero, setScoreNumero] = useState("4");
  const [operationType, setOperationType] = useState("3");
  

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 gap-5">
        <h1 className="text-3xl font-bold text-white">Controlling</h1>
        <div className="flex flex-wrap col-span-2 h-20 w-full bg-blue-300 rounded-lg items-center">
          <Filtro1Dashboard
            dataSelecionada={dataSelecionada}
            setDataSelecionada={setDataSelecionada}
            dataSelecionada2={dataSelecionada2}
            setDataSelecionada2={setDataSelecionada2}
            score={score}
            setScore={setScore}
            mtm={mtm}
            setMtm={setMtm}
            mwh={mwh}
            setMwh={setMwh}
            menorY1={menorY1}
            setMenorY1={setMenorY1}
            maiorY1={maiorY1}
            setMaiorY1={setMaiorY1}
            total={total}
            setTotal={setTotal}
            operationType={operationType}
            setOperationType={setOperationType}
          />
        </div>
        <div className="col-span-2 bg-blue-300 rounded-lg">
          <DashboardChart
            score={score}
            mtm={mtm}
            mwh={mwh}
            menorY1={menorY1}
            maiorY1={maiorY1}
            total={total}
            dataSelecionada={dataSelecionada}
            dataSelecionada2={dataSelecionada2}
            operationType={operationType}
          />
        </div>
        <div className="flex flex-wrap col-span-2 h-20 w-full bg-blue-300 rounded-lg items-center">
          <Filtro2Dashboard
            scoreNumero={scoreNumero}
            setScoreNumero={setScoreNumero}
          />
        </div>
        <div className="col-span-2 bg-blue-300 rounded-lg">
          <DashboardTable
            dataSelecionada={dataSelecionada}
            scoreNumero={scoreNumero}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
