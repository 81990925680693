import { api, requestConfig } from "../utils/config";

// Obtenha todas as curvas
const getCCEE = async (nome_empresarial) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    const res = await fetch(api + `/ccee?nome_empresarial=${nome_empresarial}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getCCEENome = async (classe_perfil_agente) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    const res = await fetch(api + `/ccee_nome?classe_perfil_agente=${classe_perfil_agente}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const cceeService = {
  getCCEE,
  getCCEENome
};

export default cceeService;