import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import processosPFService from "../services/processosPFService"; // Importe o serviço atualizado

const initialState = {
  distProcessosPF: [],
  distProcessosFamilyPF: [],
  processosJudAdmPF: [],
  processosJudAdmFamilyPF: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getDistProcessos = createAsyncThunk(
  "processospf/getdistprocessos",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPFService.getDistProcessos(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getDistProcessosFamily = createAsyncThunk(
  "processospf/getdistprocessosfamily",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPFService.getDistProcessosFamily(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProcessosJudAdm = createAsyncThunk(
  "processospf/getprocessosdudadm",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPFService.getProcessosJudAdm(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProcessosJudAdmFamily = createAsyncThunk(
  "processospf/getprocessosdudadmfamily",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPFService.getProcessosJudAdmFamily(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const processosPFSlice = createSlice({
  name: "processospf",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistProcessos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDistProcessos.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.distProcessosPF = action.payload;
      })
      .addCase(getDistProcessos.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getDistProcessosFamily.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDistProcessosFamily.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.distProcessosFamilyPF = action.payload;
      })
      .addCase(getDistProcessosFamily.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProcessosJudAdm.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProcessosJudAdm.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.processosJudAdmPF = action.payload;
      })
      .addCase(getProcessosJudAdm.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProcessosJudAdmFamily.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProcessosJudAdmFamily.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.processosJudAdmFamilyPF = action.payload;
      })
      .addCase(getProcessosJudAdmFamily.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = processosPFSlice.actions;
export default processosPFSlice.reducer;
