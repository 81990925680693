import { Link } from "react-router-dom";
import { useState } from "react";
import {
  BiSolidDashboard,
  BiMenu,
  BiChevronUp,
  BiChevronDown,
} from "react-icons/bi";
import { GiMaterialsScience } from "react-icons/gi";
import { FaIdCard, FaUsers, FaSearch } from "react-icons/fa";
import { IoAnalytics } from "react-icons/io5";

import { FaChartBar } from 'react-icons/fa';
import { FaBolt } from 'react-icons/fa';
const Sidebar = ({ open, setOpen }) => {
  const [openSubmenu, setOpenSubmenu] = useState({});

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setOpenSubmenu({});
  };

  const handleSubmenuClick = (index) => {
    setOpenSubmenu((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const Menus = [
    {
      title: "BI",
      icon: <FaChartBar />,
      links: [
        {
          title: "Controlling",
          icon: <BiSolidDashboard />,
          path: "/bi/controlling",
        },
        {
          title: "Monitoring",
          icon: <IoAnalytics />,
          path: "/bi/monitoring",
        },
        {
          title: "Agentes SEB",
          icon: <FaBolt />,
          path: "/bi/agentesseb",
        },
      ],
    },
    {
      title: "Pessoa Física",
      icon: <FaIdCard />,
      links: [
        {
          title: "Consulta CPF",
          icon: <FaSearch />,
          path: "/pessoa-fisica/score",
        },
        {
          title: "IA Generativa",
          icon: <GiMaterialsScience />,
          path: "/pessoa-fisica/IA",
        },
      ],
    },
    {
      title: "Pessoa Jurídica",
      icon: <FaUsers />,
      links: [
        // { title: "Dados Essenciais", icon: <GiMaterialsScience />, path: "/pessoa-juridica/dados-essenciais" },
        {
          title: "Consulta CNPJ",
          icon: <FaSearch />,
          path: "/pessoa-juridica/score",
        },
        // { title: "Processos", icon: <MdStackedLineChart />, path: "/pessoa-juridica/processos" },
        {
          title: "IA Generativa",
          icon: <GiMaterialsScience />,
          path: "/pessoa-juridica/IA",
        },
      ],
    },
  ];

  return (
    <div
      className={`h-screen fixed bg-[#023E8A] p-3 pt-8 transition-width z-1001 ${
        open ? "w-60" : "w-20"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ minWidth: open ? "240px" : "60px" }}
    >
      <div className="flex justify-between items-center">
        <BiMenu
          className="text-white cursor-pointer ml-4 mt-4"
          onClick={() => setOpen(!open)}
        />
      </div>
      <nav>
        <ul className="mt-8">
          {Menus.map((menu, index) => (
            <li
              key={index}
              className={`text-lg font-medium ml-4 mt-4 hover:font-bold hover:bg-white hover:bg-opacity-10 ${
                openSubmenu[index] ? "text-blue-200" : "text-white"
              }`}
            >
              {menu.links ? (
                <div
                  onClick={() => handleSubmenuClick(index)}
                  className="flex items-center justify-between gap-x-2 cursor-pointer"
                >
                  <div className="flex items-center gap-x-2">
                    {menu.icon}
                    <span className={`${!open && "hidden"} ml-2`}>
                      {menu.title}
                    </span>
                  </div>
                  {open &&
                    (openSubmenu[index] ? <BiChevronUp /> : <BiChevronDown />)}
                </div>
              ) : (
                <Link
                  to={menu.route}
                  className="flex items-center gap-x-2 cursor-pointer text-white"
                >
                  {menu.icon}
                  <span className={`${!open && "hidden"} ml-2`}>
                    {menu.title}
                  </span>
                </Link>
              )}
              {openSubmenu[index] && (
                <ul className="ml-4">
                  {menu.links.map((link, linkIndex) => (
                    <li
                      key={linkIndex}
                      className="text-white text-base mt-2 flex items-center gap-x-2 font-normal hover:text-blue-200 hover:font-bold hover:bg-white hover:bg-opacity-10"
                    >
                      {link.icon}
                      <Link to={link.path} className="text-white">
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
