import React, { useEffect, useState, useCallback } from "react";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
import NavegacaoPJ from "./NavegacaoPJ";
import CNPJForm from "./CNPJForm";
import { useCNPJ } from "./CNPJContext"; // Importa o contexto
import ProtestosPJ from "./ProtestosPJ";

function PJProtestos() {
  const { cnpj, setCnpj } = useCNPJ(); // Usa o contexto para obter e definir o CNPJ

  const [submittedCNPJ, setSubmittedCNPJ] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [initialSearchDone, setInitialSearchDone] = useState(false); // Novo estado

  // Função para formatar o CNPJ enquanto o usuário digita
  const formatCNPJ = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
    }
    return cleaned;
  };

  // Função para extrair apenas números
  const getOnlyNumbers = (value) => {
    return value.replace(/\D/g, "");
  };

  // Limitar a entrada do usuário para no máximo 14 caracteres
  const handleChange = (event) => {
    let value = event.target.value;
    // Remove todos os caracteres não numéricos e limita a 14 caracteres
    value = value.replace(/\D/g, "").slice(0, 14);
    setCnpj(formatCNPJ(value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cnpjNumbers = getOnlyNumbers(cnpj);
    console.log("CNPJ enviado:", cnpjNumbers);
    setSubmittedCNPJ(cnpjNumbers); // Armazena o CNPJ quando o botão Nova Consulta é clicado
    setSubmit(true);
    setSearch(false); // Reseta o estado de search
  };

  const handleSearch = useCallback((event) => {
    if (event) event.preventDefault();
    setSubmittedCNPJ(getOnlyNumbers(cnpj));
    setSearch(true);
    setSubmit(false);
    setInitialSearchDone(true); // Marca a busca inicial como realizada
  }, [cnpj]);

  useEffect(() => {
    if (getOnlyNumbers(cnpj).length === 14 && !initialSearchDone) {
      handleSearch(); // Realiza a busca automaticamente somente se ainda não foi feita
    }
  }, [cnpj, handleSearch, initialSearchDone]); // Adiciona initialSearchDone na lista de dependências

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-full h-full px-3 md:px-0">
        <h1 className="text-xl font-bold text-white">
          Pessoa Jurídica - Protestos
        </h1>
        <p className="text-gray-300 pb-2">
          Insira o CNPJ para obter os Protestos
        </p>
        <CNPJForm
          cnpj={cnpj}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="flex justify-end w-full">
          <NavegacaoPJ />
        </div>
        <div className="rounded-lg overflow-hidden mx-3 md:mx-4 w-full">
          <table className="w-full table-fixed">
            <tbody >
              <tr>
                <td className="p-1">
                  <ProtestosPJ
                    cnpj={submittedCNPJ} // Usa o CNPJ armazenado
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PJProtestos;
