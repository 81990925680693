import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getPresCobPJ } from "../slices/scorePJSlice";

function PresCobPJ({ cnpj, search }) {
  const dispatch = useDispatch();
  const { presCobPJ } = useSelector((state) => state.scorePJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getPresCobPJ({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? presCobPJ : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].Collections ? (
        <div className="table-responsive max-w-full mt-2">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white ">
              <tr className="border-b border-gray-200">
                <th className="w-3/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Está Atualmente em Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.IsCurrentlyOnCollection
                    ? "Sim"
                    : "Não"}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Ocorrências de Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.TotalCollectionOccurrences}
                </td>
              </tr>

              <tr >
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de Cobrança nos Últimos 30 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last30DaysCollectionOccurrences
                  }
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de Cobrança nos Últimos 90 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last90DaysCollectionOccurrences
                  }
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de Cobrança nos Últimos 180 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last180DaysCollectionOccurrences
                  }
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ocorrências de Cobrança nos Últimos 365 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last365DaysCollectionOccurrences
                  }
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Origens de Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.TotalCollectionOrigins}
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Origens de Cobrança nos Últimos 30 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last30DaysCollectionOrigins
                  }
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Origens de Cobrança nos Últimos 90 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last90DaysCollectionOrigins
                  }
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Origens de Cobrança nos Últimos 180 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last180DaysCollectionOrigins
                  }
                </td>
              </tr>
              <tr>
                <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Origens de Cobrança nos Últimos 365 Dias
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .Last365DaysCollectionOrigins
                  }
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total de Meses de Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections.TotalCollectionMonths}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Meses Consecutivos de Cobrança Atuais
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .CurrentConsecutiveCollectionMonths
                  }
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Máximo de Meses Consecutivos de Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {
                    displayData.Result[0].Collections
                      .MaxConsecutiveCollectionMonths
                  }
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data da Primeira Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections
                    .TotalCollectionOccurrences === 0
                    ? ""
                    : new Date(
                        displayData.Result[0].Collections.FirstCollectionDate
                      ).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data da Última Cobrança
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].Collections
                    .TotalCollectionOccurrences === 0
                    ? ""
                    : new Date(
                        displayData.Result[0].Collections.LastCollectionDate
                      ).toLocaleDateString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default PresCobPJ;
