import { api, requestConfig } from "../utils/config";

const getIAPJ = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/iapj/${cnpj}`);
    const res = await fetch(api + `/iapj/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const iaPFService = {
  getIAPJ
};

export default iaPFService;