import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getDistProcessosPJ } from '../slices/processosPJSlice';

function DistProcessosPJ({ cnpj, search }) {
  const dispatch = useDispatch();
  const { distProcessosPJ } = useSelector((state) => state.processosPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getDistProcessosPJ({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? distProcessosPJ : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && displayData && displayData.Result && displayData.Result[0] && displayData.Result[0].LawsuitsDistributionData ? (
        <React.Fragment>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Total de Processos
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].LawsuitsDistributionData.TotalLawsuits}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Distribuição por Tipo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {Object.entries(displayData.Result[0].LawsuitsDistributionData.TypeDistribution).map(
                    ([key, value], index) => (
                      <div key={index}>{`${key}: ${value}`}</div>
                    )
                  )}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Distribuição por Tribunal
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {Object.entries(displayData.Result[0].LawsuitsDistributionData.CourtNameDistribution).map(
                    ([key, value], index) => (
                      <div key={index}>{`${key}: ${value}`}</div>
                    )
                  )}
                </td>
              </tr>
              {/* Adicione mais linhas aqui para outros dados que você deseja exibir */}
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        !loading && submitted && !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default DistProcessosPJ;
