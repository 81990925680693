import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import { getIndicadores } from "../slices/dadosEssenciaisPFSlice"; // Altere para o caminho correto da sua ação

function Indicadores({ cpf, submit, setSubmit, search }) {
  const dispatch = useDispatch();
  const { indicadoresPF } = useSelector((state) => state.dadosEssenciaisPF);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Limpa o erro anterior antes de uma nova solicitação
        try {
          if (search) {
            // Despacha a ação Redux para buscar os dados
            await dispatch(getIndicadores({ cpf }));
          } else {
            // Faz a requisição à API
            const result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/indicadores`, { cpf });
            setData(result.data);
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setError('Erro ao buscar dados. Por favor, tente novamente mais tarde.'); // Define a mensagem de erro
        }
        setLoading(false);
        setSubmitted(true); // Marca a busca como concluída
        setSubmit(false); // Reseta o estado de submit após a busca
      };

      fetchData();
    }
  }, [submit, search, cpf, dispatch, setSubmit]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? indicadoresPF : data;

  // Função auxiliar para formatar a data
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Função auxiliar para formatar números
  const formatNumber = (number) => {
    return number.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && !error && displayData && displayData.Result && displayData.Result[0] && displayData.Result[0].FlagsAndFeatures ? (
        <div className="flex flex-wrap">
          {displayData.Result[0].FlagsAndFeatures.map((feature, index) => (
            <div key={index} className="p-3 border-b border-gray-200 w-1/2">
              <h2 className="font-semibold text-sm text-gray-800">{feature.ModelName}</h2>
              <p><strong>Descrição:</strong> {feature.ModelDescription}</p>
              <p><strong>Classificação:</strong> {feature.ModelRating}</p>
              <p><strong>Pontuação:</strong> {formatNumber(feature.ModelScore)}</p>
              <p><strong>Data de Cálculo:</strong> {formatDate(feature.CalculationDate)}</p>
            </div>
          ))}
        </div>
      ) : (
        !loading && submitted && !error && (
          <p>Não foram encontrados dados para renderizar. Por favor, verifique a resposta da API.</p>
        )
      )}
    </div>
  );
}

export default Indicadores;
