import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";

import { CNPJProvider } from "./components/CNPJContext";

Amplify.configure(awsmobile);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CNPJProvider>
        <App />
      </CNPJProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
