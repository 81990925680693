import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavegacaoPF = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Determina qual visão está ativa com base no caminho atual
  let currentView = '';
  if (currentPath.includes('processos')) {
    currentView = 'processos';
  } else if (currentPath.includes('dados-essenciais')) {
    currentView = 'dados essenciais';
  } else if (currentPath.includes('score')) {
    currentView = 'score';
  }

  return (
    <div className="flex mx-3 md:mx-4 mt-5 mb-5">
      {currentView === 'processos' && (
        <>
          <Link
            to="/pessoa-fisica/score"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Score
          </Link>
          <Link
            to="/pessoa-fisica/dados-essenciais"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Dados Essenciais
          </Link>
        </>
      )}
      {currentView === 'dados essenciais' && (
        <>
          <Link
            to="/pessoa-fisica/score"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Score
          </Link>
          <Link
            to="/pessoa-fisica/processos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Processos
          </Link>
        </>
      )}
      {currentView === 'score' && (
        <>
          <Link
            to="/pessoa-fisica/dados-essenciais"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Dados Essenciais
          </Link>
          <Link
            to="/pessoa-fisica/processos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Processos
          </Link>
        </>
      )}
    </div>
  );
};

export default NavegacaoPF;
