import { api, requestConfig } from "../utils/config";

const getIAPF = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/iapf/${cpf}`);
    const res = await fetch(api + `/iapf/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const iaPFService = {
  getIAPF
};

export default iaPFService;