// CNPJForm.js
import React from "react";

function CNPJForm({ cnpj, onChange, onSubmit, onSearch }) {
  return (
    <div className="relative w-full">
      <div className="flex items-center gap-2 justify-center">
        <input
          type="text"
          name="cnpj"
          value={cnpj}
          onChange={onChange}
          className="border p-2"
          maxLength={18} // 14 dígitos + 4 caracteres de formatação
        />
        <input
          type="submit"
          value="Histórico"
          onClick={onSearch}
          className="bg-blue-500 text-white p-2 cursor-pointer"
        />
      </div>
      <input
        type="submit"
        value="Nova Consulta"
        onClick={onSubmit}
        className="bg-blue-500 text-white p-2 cursor-pointer absolute -top-10 right-10"
      />
    </div>
  );
}

export default CNPJForm;
