import { api, requestConfig } from "../utils/config";

const getPresCobPJ = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/pres_cob_pj/${cnpj}`);
    const res = await fetch(api + `/pres_cob_pj/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getScoreQuod = async (cnpj) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/score_quod/${cnpj}`);
    const res = await fetch(api + `/score_quod/${cnpj}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const scorePFService = {
  getPresCobPJ,
  getScoreQuod
};

export default scorePFService;