import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cceeService from "../services/cceeService"; // Importe o serviço atualizado

const initialState = {
  ccee: [],
  cceeNomes: [],
  cceeClasses: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getCCEE = createAsyncThunk(
  "ccee/getccee", 
  async ({nome_empresarial, classe_perfil_agente}, thunkAPI) => {
    const data = await cceeService.getCCEE(nome_empresarial, classe_perfil_agente);

    return data;
});

export const getCCEENome = createAsyncThunk(
  "ccee/getcceenome", 
  async ({classe_perfil_agente}, thunkAPI) => {
    const data = await cceeService.getCCEENome(classe_perfil_agente);

    return data;
});

export const cceeSlice = createSlice({
  name: "ccee",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCCEE.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCCEE.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.ccee = action.payload;
      })
      .addCase(getCCEE.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getCCEENome.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCCEENome.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.cceeNomes = action.payload;
      })
      .addCase(getCCEENome.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = cceeSlice.actions;
export default cceeSlice.reducer;
