import { api, requestConfig } from "../utils/config";

const getPresCob = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/pres_cob/${cpf}`);
    const res = await fetch(api + `/pres_cob/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getProbNeg = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/prob_neg/${cpf}`);
    const res = await fetch(api + `/prob_neg/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getRiscoFinanceiro = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/risco_financeiro/${cpf}`);
    const res = await fetch(api + `/risco_financeiro/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const scorePFService = {
  getPresCob,
  getProbNeg,
  getRiscoFinanceiro
};

export default scorePFService;