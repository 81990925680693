import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getProtestosPJ, getProtestosSPPJ } from "../slices/protestosPJSlice";
import Popup from "./Popup";

function ProtestosPJ({ cnpj, submit, setSubmit, search }) {
  const dispatch = useDispatch();
  const { protestosPJ, protestosSPPJ } = useSelector((state) => state.protestosPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCartorio, setSelectedCartorio] = useState(null);

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Limpa o erro anterior antes de uma nova solicitação

        try {
          if (search) {
            // Despacha a ação Redux para buscar os dados
            await dispatch(getProtestosPJ({ cnpj }));
            await dispatch(getProtestosSPPJ({ cnpj }));
          } else {
            // Faz o primeiro POST
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/consultarcnpj`,
              { cnpj }
            );
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setError("Erro ao buscar dados. Por favor, tente novamente mais tarde."); // Define a mensagem de erro
        }

        setLoading(false);
        setSubmitted(true); // Marca a busca como concluída
        setSubmit(false); // Reseta o estado de submit após a busca
      };

      fetchData();
    }
  }, [submit, search, dispatch, cnpj, setSubmit]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? protestosPJ : [];

  // Adiciona os protestos de SP ao displayData
  const combinedData = displayData?.[0] ? {
    ...displayData[0],
    cartorios: {
      ...displayData[0].cartorios,
      ...((search ? protestosSPPJ : [])?.[0]?.cartorios || {}),
    }
  } : null;

  // Ordena os estados alfabeticamente
  const sortedStates = combinedData?.cartorios
    ? Object.keys(combinedData.cartorios).sort()
    : [];

  const formatarCapital = (valor) => {
    if (typeof valor !== "number") {
      valor = parseFloat(valor);
    }
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const handleViewDetails = (cartorio) => {
    setSelectedCartorio(cartorio);
    setShowPopup(true);
  };

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      combinedData &&
      combinedData.cartorios ? (
        <React.Fragment>
          {sortedStates.map((estado) => (
            <div
              key={estado}
              className="mb-6 bg-white rounded-lg overflow-hidden"
            >
              <h2 className="text-lg font-semibold mb-2">{estado}</h2>
              <table className="w-full border border-gray-300 ">
                <thead>
                  <tr className="bg-gray-100 border-b border-gray-300">
                    <th className="w-2/5 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cartório
                    </th>
                    <th className="w-1/5 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cidade
                    </th>
                    <th className="w-1/5 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Quantidade
                    </th>
                    <th className="w-1/5 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valor
                    </th>
                    <th className="w-1/5 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {combinedData.cartorios[estado].map(
                    (cartorio, cartorioIndex) => {
                      const totalProtestos = cartorio.protestos.length;
                      const totalValor = cartorio.protestos.reduce(
                        (acc, p) => acc + parseFloat(p.valor || 0),
                        0
                      );

                      return (
                        <tr key={`${estado}-${cartorioIndex}`}>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {cartorio.nome || "N/A"}
                          </td>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {estado === "SP" ? cartorio.comarca : cartorio.cidade || "N/A"}
                          </td>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {totalProtestos}
                          </td>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {totalProtestos > 0
                              ? formatarCapital(totalValor)
                              : "N/A"}
                          </td>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            <button
                              onClick={() => handleViewDetails(cartorio)}
                              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 whitespace-nowrap"
                            >
                              Ver Detalhes
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          ))}
          <Popup
            show={showPopup}
            onClose={() => setShowPopup(false)}
            cartorio={selectedCartorio}
          />
        </React.Fragment>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default ProtestosPJ;
