import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCurvaForward } from "../../slices/curvaSlice";
import { format, isSameDay, subMonths, getMonth, getYear } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Filtro1Dashboard = ({
  dataSelecionada,
  setDataSelecionada,
  dataSelecionada2,
  setDataSelecionada2,
  score,
  setScore,
  mtm,
  setMtm,
  mwh,
  setMwh,
  menorY1,
  setMenorY1,
  maiorY1,
  setMaiorY1,
  total,
  setTotal,
  operationType,
  setOperationType,
}) => {
  const dispatch = useDispatch();
  const { dataCurvas } = useSelector((state) => state.curva);

  const datas = dataCurvas.map((curva) => curva.data);
  const ultimaData = datas[datas.length - 1];

  const penultimoMes = subMonths(new Date(ultimaData), 1);
  const penultimoMesNumero = getMonth(penultimoMes);
  const penultimoAno = getYear(penultimoMes);

  // Calculando a variável passadadata (última data do penúltimo mês)
  const passadadata = datas
    .filter((data) => {
      const dataDate = new Date(data);
      return (
        getMonth(dataDate) === penultimoMesNumero &&
        getYear(dataDate) === penultimoAno
      );
    })
    .pop(); // A última data do penúltimo mês

  useEffect(() => {
    dispatch(getAllCurvaForward());
  }, [dispatch]);

  useEffect(() => {
    if (!dataSelecionada) {
      setDataSelecionada(passadadata);
    }
  }, [dataSelecionada, setDataSelecionada, passadadata]);

  useEffect(() => {
    if (!dataSelecionada2) {
      setDataSelecionada2(ultimaData); // Setando a data2 como a passadadata por padrão
    }
  }, [dataSelecionada2, setDataSelecionada2, ultimaData]);

  const getAllDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  function ajustarData(data) {
    var dataAjustada = new Date(
      data.getTime() + data.getTimezoneOffset() * 60 * 1000
    );
    return dataAjustada;
  }

  const primeiraDataAjustada = new Date(datas[0]);
  primeiraDataAjustada.setDate(primeiraDataAjustada.getDate());

  const ultimaDataAjustada = new Date(ultimaData);
  ultimaDataAjustada.setDate(ultimaDataAjustada.getDate() + 1);

  const todasAsDatas = getAllDatesBetween(
    ajustarData(new Date(datas[0])),
    ajustarData(new Date(ultimaData))
  );

  const datasSemFimDeSemana = todasAsDatas.filter((data) => {
    const diaDaSemana = data.getDay();
    return diaDaSemana === 0 || diaDaSemana === 6;
  });

  const handleSelecionarTipo = (tipo) => {
    switch (tipo) {
      case "mwh":
        setMwh(true);
        setMtm(false);
        setScore(false);
        break;
      case "mtm":
        setMwh(false);
        setMtm(true);
        setScore(false);
        break;
      case "score":
        setMwh(false);
        setMtm(false);
        setScore(true);
        break;
      default:
        break;
    }
  };

  const handleSelecionarVector = (tipo) => {
    switch (tipo) {
      case "menorY1":
        setMenorY1(true);
        setMaiorY1(false);
        setTotal(false);
        break;
      case "maiorY1":
        setMenorY1(false);
        setMaiorY1(true);
        setTotal(false);
        break;
      case "total":
        setMenorY1(false);
        setMaiorY1(false);
        setTotal(true);
        break;
      default:
        break;
    }
  };

  const handleSelecionarData = (data) => {
    const novaDataSelecionada = format(new Date(data), "yyyy-MM-dd");
    setDataSelecionada(novaDataSelecionada);
  };

  const handleSelecionarData2 = (data) => {
    const novaDataSelecionada2 = format(new Date(data), "yyyy-MM-dd");
    setDataSelecionada2(novaDataSelecionada2);
  };

  const handleSelecionarOperacao = (operationType) => {
    setOperationType(operationType);
  };

  return (
    <div className="flex flex-col gap-x-5">
      <div className="flex pl-5">
        <div className="flex flex-col">
          <span className="filtro">Escolha a Data Anterior:</span>
          <DatePicker
            selected={
              dataSelecionada ? ajustarData(new Date(dataSelecionada)) : null
            }
            onChange={handleSelecionarData}
            placeholderText="Data Anterior"
            showYearDropdown
            dateFormat="yyyy-MM-dd"
            minDate={primeiraDataAjustada}
            maxDate={ultimaDataAjustada}
            filterDate={(date) =>
              datas.includes(format(date, "yyyy-MM-dd")) &&
              !datasSemFimDeSemana.some((d) => isSameDay(date, new Date(d)))
            }
            className="rounded-lg"
          />
        </div>
        <div className="flex flex-col pl-5">
          <span className="filtro">Escolha a Data Processo:</span>
          <DatePicker
            selected={
              dataSelecionada2 ? ajustarData(new Date(dataSelecionada2)) : null
            }
            onChange={handleSelecionarData2}
            placeholderText="Data Processo"
            showYearDropdown
            dateFormat="yyyy-MM-dd"
            minDate={primeiraDataAjustada}
            maxDate={ultimaDataAjustada}
            filterDate={(date) =>
              datas.includes(format(date, "yyyy-MM-dd")) &&
              !datasSemFimDeSemana.some((d) => isSameDay(date, new Date(d)))
            }
            className="rounded-lg"
          />
        </div>

        <div className="flex flex-col pl-5">
          <span className="filtro">Escolha o Tipo:</span>
          <select
            className="rounded-lg"
            value={score ? "score" : mtm ? "mtm" : "mwh"}
            onChange={(e) => handleSelecionarTipo(e.target.value)}
          >
            <option value="mwh">MWh</option>
            <option value="mtm">MtM</option>
            <option value="score">Quantidade</option>
          </select>
        </div>
        <div className="flex flex-col pl-5">
          <span className="filtro">Escolha o Período:</span>
          <select
            className="rounded-lg"
            value={menorY1 ? "menorY1" : maiorY1 ? "maiorY1" : "total"}
            onChange={(e) => handleSelecionarVector(e.target.value)}
          >
            <option value="menorY1">&lt;= Y1</option>
            <option value="maiorY1">&gt; Y1</option>
            <option value="total">Total</option>
          </select>
        </div>
        <div className="flex flex-col pl-5">
          <span className="filtro">Escolha a Operação:</span>
          <select
            className="rounded-lg"
            value={operationType}
            onChange={(e) => handleSelecionarOperacao(e.target.value)}
          >
            <option value="1">Compra</option>
            <option value="2">Venda</option>
            <option value="3">Net</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Filtro1Dashboard;
