import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import processosPJService from "../services/processosPJService"; // Importe o serviço atualizado

const initialState = {
  distProcessosPJ: [],
  distProcessosOwnPJ: [],
  processosEmpresasPJ: [],
  processosOwnPJ: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getDistProcessosPJ = createAsyncThunk(
  "processospj/getdistprocessospj",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPJService.getDistProcessosPJ(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getDistProcessosOwn = createAsyncThunk(
  "processospj/getdistprocessosown",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPJService.getDistProcessosOwn(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProcessosEmpresas = createAsyncThunk(
  "processospj/getprocessosempresas",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPJService.getProcessosEmpresas(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProcessosOwn = createAsyncThunk(
  "processospj/getprocessosown",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await processosPJService.getProcessosOwn(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const processosPJSlice = createSlice({
  name: "processospj",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistProcessosPJ.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDistProcessosPJ.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.distProcessosPJ = action.payload;
      })
      .addCase(getDistProcessosPJ.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getDistProcessosOwn.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDistProcessosOwn.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.distProcessosOwnPJ = action.payload;
      })
      .addCase(getDistProcessosOwn.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProcessosEmpresas.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProcessosEmpresas.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.processosEmpresasPJ = action.payload;
      })
      .addCase(getProcessosEmpresas.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProcessosOwn.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProcessosOwn.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.processosOwnPJ = action.payload;
      })
      .addCase(getProcessosOwn.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = processosPJSlice.actions;
export default processosPJSlice.reducer;
