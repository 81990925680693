import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dadosEssenciaisPFService from "../services/dadosEssenciaisPFService"; // Importe o serviço atualizado

const initialState = {
  dadosBasicosPF: [],
  financeiroPF: [],
  profissionaisPF: [],
  indicadoresPF: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getDadosBasicos = createAsyncThunk(
  "dadosessenciaispf/getdadosbasicos",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPFService.getDadosBasicos(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getFinanceiro = createAsyncThunk(
  "dadosessenciaispf/getfinanceiro",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPFService.getFinanceiro(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProfissionais = createAsyncThunk(
  "dadosessenciaispf/getprofissionais",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPFService.getProfissionais(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getIndicadores = createAsyncThunk(
  "dadosessenciaispf/getindicadores",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPFService.getIndicadores(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const dadosEssenciaisPFSlice = createSlice({
  name: "dadosessenciaispf",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDadosBasicos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDadosBasicos.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dadosBasicosPF = action.payload;
      })
      .addCase(getDadosBasicos.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getFinanceiro.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getFinanceiro.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.financeiroPF = action.payload;
      })
      .addCase(getFinanceiro.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProfissionais.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProfissionais.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.profissionaisPF = action.payload;
      })
      .addCase(getProfissionais.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getIndicadores.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getIndicadores.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.indicadoresPF = action.payload;
      })
      .addCase(getIndicadores.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = dadosEssenciaisPFSlice.actions;
export default dadosEssenciaisPFSlice.reducer;
