import { api, requestConfig } from "../utils/config";

const getDashboard = async (data1, data2, operationType) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dashboard?data1=${data1}&data2=${data2}&operationType=${operationType}`);
    const res = await fetch(api + `/dashboard?data1=${data1}&data2=${data2}&operationType=${operationType}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getDashboard2 = async (data1, score) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dashboard2?data1=${data1}&score=${score}`);
    const res = await fetch(api + `/dashboard2?data1=${data1}&score=${score}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const dashboardService = {
  getDashboard,
  getDashboard2
};

export default dashboardService;