import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import iaPJService from "../services/iaPJService"; // Importe o serviço atualizado

const initialState = {
  iaPJ: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getIAPJ = createAsyncThunk(
  "iapj/getiapj",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await iaPJService.getIAPJ(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const iaPJSlice = createSlice({
  name: "iapj",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIAPJ.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getIAPJ.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.iaPJ = action.payload;
      })
      .addCase(getIAPJ.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = iaPJSlice.actions;
export default iaPJSlice.reducer;
