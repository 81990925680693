import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import { getIAPF } from "../slices/iaPFSlice";

function IAPF({ cpf, submit, setSubmit, search }) {
  const dispatch = useDispatch();
  const { iaPF } = useSelector((state) => state.iaPF);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Limpa o erro anterior antes de uma nova solicitação
        try {
          if (search) {
            // Despacha a ação Redux para buscar os dados
            await dispatch(getIAPF({ cpf }));
          } else {
            // Faz a requisição à API
            const result = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/iapf`,
              { cpf }
            );
            setData(result.data);
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
          setError(
            "Erro ao buscar dados. Por favor, tente novamente mais tarde."
          ); // Define a mensagem de erro
        }
        setLoading(false);
        setSubmitted(true); // Marca a busca como concluída
        setSubmit(false); // Reseta o estado de submit após a busca
      };

      fetchData();
    }
  }, [submit, search, cpf, dispatch, setSubmit]);

  // Define as substituições de texto
  const replacements = [
    { search: "BigData Corp", replace: "Belpit" },
    { search: "BigData Corporation", replace: "Belpit" },
    // Adicione mais substituições conforme necessário
  ];

  // Função para aplicar substituições ao texto
  const replaceText = (text) => {
    let newText = text;
    replacements.forEach(({ search, replace }) => {
      const regex = new RegExp(search, "gi"); // 'gi' para buscar de forma global e sem distinguir maiúsculas de minúsculas
      newText = newText.replace(regex, replace);
    });
    return newText;
  };

  const displayData = search ? iaPF : data;

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      !error &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].GenerativeAIData ? (
        <div className="table-responsive max-w-full">
          <table className="w-full min-w-full divide-y divide-gray-200 text-left">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <th className="w-1/4  px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nome do Serviço
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].GenerativeAIData[0].ServiceName}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Versão do Serviço
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].GenerativeAIData[0].ServiceVersion}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nome do Modelo de IA
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].GenerativeAIData[0].AIModelName}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Descrição
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800 pr-20 pb-5">
                  {replaceText(displayData.Result[0].GenerativeAIData[0].Description)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default IAPF;
