import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavegacaoPJ = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Determina qual visão está ativa com base no caminho atual
  let currentView = "";
  if (currentPath.includes("processos")) {
    currentView = "processos";
  } else if (currentPath.includes("dados-essenciais")) {
    currentView = "dados essenciais";
  } else if (currentPath.includes("score")) {
    currentView = "score";
  } else if (currentPath.includes("protestos")) {
    currentView = "protestos";
  }

  return (
    <div className="flex mx-3 md:mx-4 mt-5 mb-5">
      {currentView === "processos" && (
        <>
          <Link
            to="/pessoa-juridica/score"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Score
          </Link>
          <Link
            to="/pessoa-juridica/dados-essenciais"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Dados Essenciais
          </Link>
          <Link
            to="/pessoa-juridica/protestos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Protestos
          </Link>
        </>
      )}
      {currentView === "dados essenciais" && (
        <>
          <Link
            to="/pessoa-juridica/score"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Score
          </Link>
          <Link
            to="/pessoa-juridica/processos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Processos
          </Link>
          <Link
            to="/pessoa-juridica/protestos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Protestos
          </Link>
        </>
      )}
      {currentView === "score" && (
        <>
          <Link
            to="/pessoa-juridica/dados-essenciais"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Dados Essenciais
          </Link>
          <Link
            to="/pessoa-juridica/processos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Processos
          </Link>
          <Link
            to="/pessoa-juridica/protestos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Protestos
          </Link>
        </>
      )}
      {currentView === "protestos" && (
        <>
          <Link
            to="/pessoa-juridica/score"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Score
          </Link>
          <Link
            to="/pessoa-juridica/dados-essenciais"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Dados Essenciais
          </Link>
          <Link
            to="/pessoa-juridica/processos"
            className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
          >
            Processos
          </Link>
        </>
      )}
    </div>
  );
};

export default NavegacaoPJ;
