import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import curvaService from "../services/curvaService"; // Importe o serviço atualizado

const initialState = {
  dataCurvas: [],
  dataCurvas2: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getAllCurvaForward = createAsyncThunk(
  "curva/getallcurva", 
  async (_, thunkAPI) => {
    const data = await curvaService.getAllCurvaForward();

    return data;
});

export const curvaSlice = createSlice({
  name: "curva",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCurvaForward.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllCurvaForward.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dataCurvas = action.payload;
        state.dataCurvas2 = action.payload;
      })
      .addCase(getAllCurvaForward.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = curvaSlice.actions;
export default curvaSlice.reducer;
