import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dadosEssenciaisPJService from "../services/dadosEssenciaisPJService"; // Importe o serviço atualizado

const initialState = {
  dadosBasicosPJ: [],
  dadosBasicosHistoricoPJ: [],
  evolucaoPJ: [],
  dividaPJ: [],
  atividadePJ: [],
  relacoesPJ: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getDadosBasicosEmpresa = createAsyncThunk(
  "dadosessenciaispj/getdadosbasicosempresa",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPJService.getDadosBasicosEmpresa(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getDadosBasicosEmpresaHistorico = createAsyncThunk(
  "dadosessenciaispj/getdadosbasicosempresahistorico",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPJService.getDadosBasicosEmpresaHistorico(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getEvolucaoEmpresa = createAsyncThunk(
  "dadosessenciaispj/getevolucaoempresa",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPJService.getEvolucaoEmpresa(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getDividas = createAsyncThunk(
  "dadosessenciaispj/getdividas",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPJService.getDividas(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getAtividades = createAsyncThunk(
  "dadosessenciaispj/getatividades",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPJService.getAtividades(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getRelacoes = createAsyncThunk(
  "dadosessenciaispj/getrelacoes",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dadosEssenciaisPJService.getRelacoes(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const dadosEssenciaisPJSlice = createSlice({
  name: "dadosessenciaispj",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDadosBasicosEmpresa.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDadosBasicosEmpresa.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dadosBasicosPJ = action.payload;
      })
      .addCase(getDadosBasicosEmpresa.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getDadosBasicosEmpresaHistorico.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDadosBasicosEmpresaHistorico.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dadosBasicosHistoricoPJ = action.payload;
      })
      .addCase(getDadosBasicosEmpresaHistorico.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getEvolucaoEmpresa.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getEvolucaoEmpresa.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.evolucaoPJ = action.payload;
      })
      .addCase(getEvolucaoEmpresa.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getDividas.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDividas.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dividaPJ = action.payload;
      })
      .addCase(getDividas.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getAtividades.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAtividades.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.atividadePJ = action.payload;
      })
      .addCase(getAtividades.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getRelacoes.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getRelacoes.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.relacoesPJ = action.payload;
      })
      .addCase(getRelacoes.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = dadosEssenciaisPJSlice.actions;
export default dadosEssenciaisPJSlice.reducer;
