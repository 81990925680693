const Popup = ({ show, onClose, cartorio }) => {
  if (!show || !cartorio) return null;

  const formatarCapital = (valor) => {
    if (typeof valor !== "number") {
      valor = parseFloat(valor);
    }
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const formatCNPJ = (cnpj) => {
    if (!cnpj) return ""; // ou um valor padrão que você preferir

    // Remove caracteres não numéricos
    const cnpjNumbers = cnpj.replace(/\D/g, "");

    // Aplica a formatação
    return cnpjNumbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const formatarTelefone = (telefone) => {
    if (!telefone) return "Não disponível"; // ou um valor padrão que você preferir

    // Remove caracteres não numéricos
    const telefoneNumbers = telefone.replace(/\D/g, "");

    // Verifica se o telefone tem o comprimento esperado (com DDD e número)
    if (telefoneNumbers.length === 10) {
      // Formato: (XX) XXXX-XXXX
      return telefoneNumbers.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else if (telefoneNumbers.length === 11) {
      // Formato: (XX) XXXXX-XXXX
      return telefoneNumbers.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else {
      return "Número inválido";
    }
  };

  const totalProtestos = cartorio.protestos.length;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="relative bg-white p-6 pt-2 rounded-lg shadow-lg w-11/12 md:w-1/2 max-h-[80vh] overflow-auto">
        <button
          className="absolute top-2 right-2 text-gray-500 text-2xl"
          onClick={onClose}
        >
          ×
        </button>
        <div className="flex items-center mb-4">
          <h3 className="text-lg font-semibold flex-1">
            {cartorio.nome || "Nome do Cartório"}
          </h3>
        </div>
        <div className="mb-4">
          <p className="text-sm text-gray-700">
            <strong>Endereço:</strong> {cartorio.endereco || "Não disponível"}
          </p>
          <p className="text-sm text-gray-700">
            <strong>Telefone:</strong> {formatarTelefone(cartorio.telefone)}
          </p>

          <p className="text-sm text-gray-700">
            <strong>Quantidade de Protestos:</strong> {totalProtestos}
          </p>
        </div>
        <table className="w-full border border-gray-300 mb-4">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-300">
              <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Documento
              </th>
              <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            {cartorio.protestos.map((protesto, index) => (
              <tr key={index}>
                <td className="px-3 py-1 text-sm text-gray-800">
                  {formatCNPJ(protesto.cpf_cnpj || "N/A")}
                </td>
                <td className="px-3 py-1 text-sm text-gray-800">
                  {formatarCapital(protesto.valor)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          onClick={onClose}
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

export default Popup;
