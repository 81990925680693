import { api, requestConfig } from "../utils/config";

const getDistProcessos = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dist_processos/${cpf}`);
    const res = await fetch(api + `/dist_processos/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getDistProcessosFamily = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dist_processos_family/${cpf}`);
    const res = await fetch(api + `/dist_processos_family/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getProcessosJudAdm = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/processos_jud_adm/${cpf}`);
    const res = await fetch(api + `/processos_jud_adm/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getProcessosJudAdmFamily = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/processos_jud_adm_family/${cpf}`);
    const res = await fetch(api + `/processos_jud_adm_family/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const processosPFService = {
  getDistProcessos,
  getDistProcessosFamily,
  getProcessosJudAdm,
  getProcessosJudAdmFamily
};

export default processosPFService;