import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner'; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from 'react-redux';
import { getProfissionais } from '../slices/dadosEssenciaisPFSlice'; // Altere para o caminho correto da sua ação

function DadosProfissionais({ cpf, submit, setSubmit, search }) {
  const dispatch = useDispatch();
  const { profissionaisPF } = useSelector((state) => state.dadosEssenciaisPF);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Limpa o erro anterior antes de uma nova solicitação
        try {
          if (search) {
            // Despacha a ação Redux para buscar os dados
            await dispatch(getProfissionais({ cpf }));
          } else {
            // Faz a requisição à API
            const result = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/profissionais`,
              { cpf }
            );
            setData(result.data);
          }
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
          setError('Erro ao buscar dados. Por favor, tente novamente mais tarde.'); // Define a mensagem de erro
        }
        setLoading(false);
        setSubmitted(true); // Marca a busca como concluída
        setSubmit(false); // Reseta o estado de submit após a busca
      };

      fetchData();
    }
  }, [submit, search, cpf, dispatch, setSubmit]);

  const formatCNPJ = (cnpj) => {
    if (!cnpj) return ''; // ou um valor padrão que você preferir

    // Remove caracteres não numéricos
    const cnpjNumbers = cnpj.replace(/\D/g, '');

    // Aplica a formatação
    return cnpjNumbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  };

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? profissionaisPF : data;

  const sortedProfessions = displayData?.Result?.[0]?.ProfessionData?.Professions
    ? [...displayData.Result[0].ProfessionData.Professions].sort((a, b) => {
        const aEndDate = a.EndDate === '9999-12-31T23:59:59.9999999' ? Date.now() : new Date(a.EndDate);
        const bEndDate = b.EndDate === '9999-12-31T23:59:59.9999999' ? Date.now() : new Date(b.EndDate);
        const aStartDate = new Date(a.StartDate);
        const bStartDate = new Date(b.StartDate);

        // Primeiro ordena por EndDate em ordem decrescente
        if (aEndDate < bEndDate) return 1;
        if (aEndDate > bEndDate) return -1;

        // Em seguida, ordena por StartDate em ordem decrescente
        if (aStartDate < bStartDate) return 1;
        if (aStartDate > bStartDate) return -1;

        return 0;
      })
    : []; // Garantia de que `sortedProfessions` seja um array, mesmo quando `Professions` for indefinido

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && sortedProfessions.length > 0 && (
        <div className="table-responsive max-w-full">
          {sortedProfessions.slice(0, 2).map((profession, index) => (
            <div key={index} className="mb-4">
              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Nome da Empresa
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800 break-words">
                      {profession.CompanyName}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      CNPJ
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800 break-words">
                      {formatCNPJ(profession.CompanyIdNumber)}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Nível
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800 break-words">
                      {profession.Level}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Estado
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800 break-words">
                      {profession.Status}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Data Começo
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800 break-words">
                      {new Date(profession.StartDate).toLocaleDateString()}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Data Fim
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800 break-words">
                      {profession.Status === 'ACTIVE' ? '' : new Date(profession.EndDate).toLocaleDateString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
      {!loading && submitted && sortedProfessions.length === 0 && (
        <p>Não foram encontrados dados para renderizar. Por favor, verifique a resposta da API.</p>
      )}
    </div>
  );
}

export default DadosProfissionais;
