import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner"; // Certifique-se de ter instalado react-loader-spinner
import { useDispatch, useSelector } from "react-redux";
import {
  getDadosBasicosEmpresa,
  getDadosBasicosEmpresaHistorico,
} from "../slices/dadosEssenciaisPJSlice";

function DadosBasicosEmpresa({ cnpj, search }) {
  const dispatch = useDispatch();
  const { dadosBasicosPJ, dadosBasicosHistoricoPJ } = useSelector(
    (state) => state.dadosEssenciaisPJ
  );

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getDadosBasicosEmpresa({ cnpj }));
        await dispatch(getDadosBasicosEmpresaHistorico({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);
  
  const formatCNPJ = (cnpj) => {
    if (!cnpj) return ""; // ou um valor padrão que você preferir

    // Remove caracteres não numéricos
    const cnpjNumbers = cnpj.replace(/\D/g, "");

    // Aplica a formatação
    return cnpjNumbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const formatarCapital = (valor) => {
    if (typeof valor !== "number") {
      valor = parseFloat(valor);
    }
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? dadosBasicosPJ : [];
  const historyData = search ? dadosBasicosHistoricoPJ : [];

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && displayData?.Result?.[0]?.BasicData ? (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {/* Dados Básicos */}
              <tr>
                <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nome Oficial
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.OfficialName ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  CNPJ
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatCNPJ(displayData.Result[0].BasicData.TaxIdNumber) ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nome Fantasia
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.TradeName ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Situação Cadastral
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.TaxIdStatus ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data de Fundação
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.FoundedDate
                    ? new Date(
                        displayData.Result[0].BasicData.FoundedDate
                      ).toLocaleDateString()
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Idade
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.Age !== undefined
                    ? displayData.Result[0].BasicData.Age
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Regime Tributário
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.TaxRegime ||
                    "Não encontrado"}
                </td>
              </tr>
              {displayData.Result[0].BasicData.AdditionalOutputData ? (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Capital Social
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {formatarCapital(
                      displayData.Result[0].BasicData.AdditionalOutputData
                        .CapitalRS
                    ) || "Não encontrado"}
                  </td>
                </tr>
              ) : (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Capital Social
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    Não encontrado
                  </td>
                </tr>
              )}
              {historyData?.Result?.[0]?.HistoryBasicData?.TaxRegimeHistoryList
                ?.length > 0 ? (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Histórico de Regime Tributário
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    <ul>
                      {historyData.Result[0].HistoryBasicData.TaxRegimeHistoryList.map(
                        (item, index) => (
                          <li key={index}>
                            {item.TaxRegime} -{" "}
                            {new Date(item.ChangeDate).toLocaleDateString()}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
              ) : (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Histórico de Regime Tributário
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    Não encontrado
                  </td>
                </tr>
              )}
              {historyData?.Result?.[0]?.HistoryBasicData?.NameHistoryList
                ?.length > 0 ? (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Histórico de Nome
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    <ul>
                      {historyData.Result[0].HistoryBasicData.NameHistoryList.map(
                        (item, index) => (
                          <li key={index}>
                            {item.Name} -{" "}
                            {new Date(item.ChangeDate).toLocaleDateString()}
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
              ) : (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Histórico de Nome
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    Não encontrado
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default DadosBasicosEmpresa;
