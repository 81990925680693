import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getRelacoes } from "../slices/dadosEssenciaisPJSlice";

function Relacoes({ cnpj, search }) {
  const dispatch = useDispatch();
  const { relacoesPJ } = useSelector((state) => state.dadosEssenciaisPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getRelacoes({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  function formatDocument(number) {
    if (!number) return "Não encontrado";

    // Remove caracteres não numéricos
    const cleanNumber = number.replace(/\D+/g, "");

    if (cleanNumber.length === 11) {
      // CPF
      return cleanNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cleanNumber.length === 14) {
      // CNPJ
      return cleanNumber.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    } else {
      return "Não encontrado"; // Retorna uma mensagem para números inválidos
    }
  }

  const displayData = search ? relacoesPJ : [];

  // Função para converter strings de data em objetos Date
  function parseDate(dateString) {
    if (!dateString) return null;
    return new Date(dateString);
  }

  // Função para filtrar dados de relacionamento
  function filterData(data) {
    if (
      !displayData ||
      !displayData.Result ||
      !displayData.Result[0] ||
      !displayData.Result[0].Relationships
    )
      return [];
    const relationships = displayData.Result[0].Relationships.Relationships || [];
    const queryDate = parseDate(displayData.QueryDate);

    return relationships
      .filter((rel) => rel.RelationshipType === "QSA")
      .filter((rel) => {
        const endDate = parseDate(rel.RelationshipEndDate);
        return endDate && queryDate && endDate > queryDate; // Filtro para verificar se RelationshipEndDate > QueryDate
      });
  }

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      displayData.Result[0].Relationships &&
      displayData.Result[0].Relationships.Relationships ? (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white">
              {filterData(displayData).map((rel, index) => (
                <React.Fragment key={index}>
                  <tr className={index > 0 ? "border-t border-gray-200" : ""}>
                    <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      CPF/CNPJ
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {formatDocument(rel.RelatedEntityTaxIdNumber) || "Não encontrado"}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nome
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {rel.RelatedEntityName || "Não encontrado"}
                    </td>
                  </tr>
                  <tr>
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cargo
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {rel.RelationshipName || "Não encontrado"}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default Relacoes;
