import React from 'react';

const Filtro2Dashboard = ({ scoreNumero, setScoreNumero }) => {

  const handleSelecionarScore = (scoreNumero) => {
    setScoreNumero(scoreNumero);
  };

  return (
    <div className='flex flex-col gap-x-5 '>
      <div className='flex pl-5'>
        <div className='flex flex-col'>
          <span className='filtro'>Escolha o Score:</span>
          <select className='rounded-lg' value={scoreNumero} onChange={(e) => handleSelecionarScore(e.target.value)}>
            <option value="1">A</option>
            <option value="2">B</option>
            <option value="3">C</option>
            <option value="4">Todos</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Filtro2Dashboard;
