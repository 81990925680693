// CNPJContext.js
import React, { createContext, useContext, useState } from 'react';

const CNPJContext = createContext();

export const CNPJProvider = ({ children }) => {
  const [cnpj, setCnpj] = useState("");

  return (
    <CNPJContext.Provider value={{ cnpj, setCnpj }}>
      {children}
    </CNPJContext.Provider>
  );
};

export const useCNPJ = () => {
  return useContext(CNPJContext);
};
