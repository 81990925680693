import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getAtividades } from "../slices/dadosEssenciaisPJSlice";

function IndicadorAtividades({ cnpj, search }) {
  const dispatch = useDispatch();
  const { atividadePJ } = useSelector((state) => state.dadosEssenciaisPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const extrairNumero = (str) => {
    const match = str.match(/\d+/); // Expressão regular para encontrar o número
    return match ? match[0] : "Não disponível"; // Retorna o número encontrado ou uma mensagem padrão
  };

  const removerZerosEsquerda = (str) => {
    if (typeof str !== "string") return str; // Retorna o valor original se não for uma string
    return str
      .split(" ")
      .map((part) => part.replace(/^0+/, "")) // Remove os zeros à esquerda de cada parte
      .join(" "); // Reconstrua a string com as partes formatadas
  };

  const formatCNPJ = (cnpj) => {
    if (!cnpj) return ""; // ou um valor padrão que você preferir

    // Remove caracteres não numéricos
    const cnpjNumbers = cnpj.replace(/\D/g, "");

    // Aplica a formatação
    return cnpjNumbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getAtividades({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };
  
    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const displayData = search ? atividadePJ : [];

  // Extrai os indicadores de atividade
  const activityIndicators =
    displayData && displayData.Result && displayData.Result[0]
      ? displayData.Result[0].ActivityIndicators
      : null;

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading &&
      submitted &&
      displayData &&
      displayData.Result &&
      displayData.Result[0] &&
      activityIndicators ? (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white">
              <React.Fragment>
                <tr>
                  <th className="w-2/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Doc
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {formatCNPJ(
                      extrairNumero(displayData.Result[0].MatchKeys)
                    ) || "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    FAIXA DE FUNCIONÁRIOS
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {removerZerosEsquerda(activityIndicators.EmployeesRange) ||
                      "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    FAIXA DE RENDA
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.IncomeRange || "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nível de Atividade
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.ActivityLevel !==
                    undefined
                      ? activityIndicators.ActivityLevel.toFixed(
                          2
                        )
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nível Médio de Atividade do Grupo Econômico de Primeiro
                    Nível
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.FirstLevelEconomicGroupAverageActivityLevel !==
                    undefined
                      ? activityIndicators.FirstLevelEconomicGroupAverageActivityLevel.toFixed(
                          2
                        )
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nível máximo de atividade do grupo econômico de primeiro
                    nível
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.FirstLevelEconomicGroupMaxActivityLevel !==
                    undefined
                      ? activityIndicators.FirstLevelEconomicGroupMaxActivityLevel.toFixed(
                          2
                        )
                      : "Não encontrado"}
                  </td>
                </tr>

                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Probabilidade de empresa fachada
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.ShellCompanyLikelyhood !== undefined
                      ? activityIndicators.ShellCompanyLikelyhood.toFixed(2)
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui endereço recente
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasRecentAddress !== undefined
                      ? activityIndicators.HasRecentAddress
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui telefone recente
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasRecentPhone !== undefined
                      ? activityIndicators.HasRecentPhone
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui e-mail recente
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasRecentEmail !== undefined
                      ? activityIndicators.HasRecentEmail
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui passagens recentes
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasRecentPassages !== undefined
                      ? activityIndicators.HasRecentPassages
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui Domínio Ativo
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasActiveDomain !== undefined
                      ? activityIndicators.HasActiveDomain
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui SSL ativo
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasActiveSSL !== undefined
                      ? activityIndicators.HasActiveSSL
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
                <tr>
                  <th className="w-1/4 px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Possui e-mail corporativo
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    {activityIndicators.HasCorporateEmail !== undefined
                      ? activityIndicators.HasCorporateEmail
                        ? "Sim"
                        : "Não"
                      : "Não encontrado"}
                  </td>
                </tr>
              </React.Fragment>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default IndicadorAtividades;
