import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { getCCEE } from "../../slices/cceeSlice";

const AgentesChart = ({ nome_empresarial }) => {
  const { ccee } = useSelector((state) => state.ccee);
  const dispatch = useDispatch();

  useEffect(() => {
    if (nome_empresarial) {
      dispatch(getCCEE({ nome_empresarial }));
    }
  }, [dispatch, nome_empresarial]);

  if (!ccee || ccee.length === 0) {
    return <p className="text-black">Carregando dados...</p>;
  }

  // Agrupar dados por MES_REFERENCIA
  const groupedData = ccee.reduce((acc, item) => {
    const mesReferencia = item.MES_REFERENCIA;
    const net = item.CONTRATACAO_COMPRA - item.CONTRATACAO_VENDA;
    const valorSoma = item.VALOR_SOMA;

    if (!acc[mesReferencia]) {
      acc[mesReferencia] = {
        MES_REFERENCIA: mesReferencia,
        Net: 0,
        CONTRATACAO_VENDA: 0,
        CONTRATACAO_COMPRA: 0,
        VALOR_SOMA: 0,
      };
    }
    acc[mesReferencia].Net += net;
    acc[mesReferencia].CONTRATACAO_VENDA += item.CONTRATACAO_VENDA || 0;
    acc[mesReferencia].CONTRATACAO_COMPRA += item.CONTRATACAO_COMPRA || 0;
    acc[mesReferencia].VALOR_SOMA += valorSoma || 0;

    return acc;
  }, {});

  // Preparar dados para o gráfico
  const categories = Object.keys(groupedData);
  const seriesNet = Object.values(groupedData).map((item) => item.Net);
  const seriesVenda = Object.values(groupedData).map(
    (item) => item.CONTRATACAO_VENDA
  );
  const seriesCompra = Object.values(groupedData).map(
    (item) => item.CONTRATACAO_COMPRA
  );
  const seriesSoma = Object.values(groupedData).map((item) => item.VALOR_SOMA);

  const series = [
    {
      name: "Compra",
      data: seriesCompra,
      type: "bar",
    },
    {
      name: "Venda",
      data: seriesVenda,
      type: "bar",
    },
    {
      name: "Consumo",
      data: seriesSoma,
      type: "bar",
    },
    {
      name: "NET",
      data: seriesNet,
      type: "line",
    },
  ];

  const options = {
    chart: {
      height: 350,
      stacked: false,
    },
    stroke: {
      width: [2, 0, 0, 2], // Definir espessura para cada tipo de gráfico
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      title: {
        text: "Mês de Referência",
      },
      labels: {
        show: true,
        style: {
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      title: {
        text: "MWm",
      },
      labels: {
        formatter: function (val) {
          return val.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
    legend: {
      show: true,
      position: "top",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return val.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
    grid: {
      show: true,
    },
  };

  return (
    <div className="w-full px-5">
      <h1 className="text-black text-center text-2xl pt-5 pb-5">
        Gráfico Agentes
      </h1>
      <div id="chart" className="w-full">
        <ReactApexChart
          options={options}
          series={series}
          type="line" // Remova esta linha se não precisar definir um tipo de gráfico geral
          height={350}
        />
      </div>
    </div>
  );
};

export default AgentesChart;
