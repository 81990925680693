import { api, requestConfig } from "../utils/config";

const getDadosBasicos = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/dados_basicos/${cpf}`);
    const res = await fetch(api + `/dados_basicos/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getFinanceiro = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/financeiro/${cpf}`);
    const res = await fetch(api + `/financeiro/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getProfissionais = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/profissionais/${cpf}`);
    const res = await fetch(api + `/profissionais/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const getIndicadores = async (cpf) => {
  const config = requestConfig("GET", null); // Supondo que você tenha uma função requestConfig

  try {
    console.log("URL da solicitação:", api + `/indicadores/${cpf}`);
    const res = await fetch(api + `/indicadores/${cpf}`, config);
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Erro na solicitação:", res.status, res.statusText);
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar curvas:", error);
    return null;
  }
};

const dadosEssenciaisPFService = {
  getDadosBasicos,
  getFinanceiro,
  getProfissionais,
  getIndicadores
};

export default dadosEssenciaisPFService;