import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getAtividades,
  getDadosBasicosEmpresa,
  getDadosBasicosEmpresaHistorico,
  getDividas,
  getEvolucaoEmpresa,
} from "../slices/dadosEssenciaisPJSlice";
import { getPresCobPJ, getScoreQuod } from "../slices/scorePJSlice";
import {
  getProcessosEmpresas,
  getProcessosOwn,
} from "../slices/processosPJSlice";
import { getProtestosPJ } from "../slices/protestosPJSlice";

function DadosScore({ cnpj, search }) {
  const dispatch = useDispatch();
  const {
    dadosBasicosPJ,
    dadosBasicosHistoricoPJ,
    evolucaoPJ,
    atividadePJ,
    dividaPJ,
  } = useSelector((state) => state.dadosEssenciaisPJ);
  const { scoreQuodPJ, presCobPJ } = useSelector((state) => state.scorePJ);
  const { processosEmpresasPJ, processosOwnPJ } = useSelector(
    (state) => state.processosPJ
  );
  const { protestosPJ } = useSelector((state) => state.protestosPJ);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Limpa o erro anterior antes de uma nova solicitação
      try {
        // Despacha a ação Redux para buscar os dados
        await dispatch(getDadosBasicosEmpresa({ cnpj }));
        await dispatch(getScoreQuod({ cnpj }));
        await dispatch(getDadosBasicosEmpresaHistorico({ cnpj }));
        await dispatch(getPresCobPJ({ cnpj }));
        await dispatch(getEvolucaoEmpresa({ cnpj }));
        await dispatch(getAtividades({ cnpj }));
        await dispatch(getProcessosEmpresas({ cnpj }));
        await dispatch(getProcessosOwn({ cnpj }));
        await dispatch(getDividas({ cnpj }));
        await dispatch(getProtestosPJ({ cnpj }));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        ); // Define a mensagem de erro
      }
      setLoading(false);
      setSubmitted(true); // Marca a busca como concluída
    };

    if (search) {
      fetchData();
    }
  }, [search, cnpj, dispatch]);

  const formatarCapital = (valor) => {
    if (typeof valor !== "number") {
      valor = parseFloat(valor);
    }
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const formatCNPJ = (cnpj) => {
    if (!cnpj) return "";
    const cnpjNumbers = cnpj.replace(/\D/g, "");
    return cnpjNumbers.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const displayData = search ? dadosBasicosPJ : [];
  const scoreData = search ? scoreQuodPJ : [];
  const historyData = search ? dadosBasicosHistoricoPJ : [];
  const presCobData = search ? presCobPJ : [];
  const evolucaoData = search ? evolucaoPJ : [];
  const atividadeData = search ? atividadePJ : [];
  const processosEmpresaData = search ? processosEmpresasPJ : [];
  const processosSocioData = search ? processosOwnPJ : [];
  const dividasData = search ? dividaPJ : [];
  const protestosData = search ? protestosPJ : [];

  // Usa dados do Redux se search for verdadeiro, caso contrário, usa dados da API
  const processosEmpresaLawsuits =
    processosEmpresaData?.Result?.[0]?.Lawsuits?.Lawsuits || [];

  // Cria uma cópia do array para ordenar
  const sortedLawsuits = [...processosEmpresaLawsuits].sort(
    (a, b) => new Date(b.LastUpdate) - new Date(a.LastUpdate)
  );

  // Extrai o número do MatchKeys e remove o caractere final }
  const matchKeysString = processosEmpresaData?.Result?.[0]?.MatchKeys || null;
  const matchKeys = matchKeysString
    ? matchKeysString.replace(/^\D+|\D+$/g, "")
    : null;

  // Função para comparar strings ignorando maiúsculas e minúsculas
  const normalizeString = (str) => (str ? str.toLowerCase() : "");

  // Verifica o Doc e a Polarity em todos os objetos de Parties
  const findMatchingDocAndPolarity = (parties) => {
    for (const party of parties) {
      const partyDoc = party?.Doc ? party.Doc.replace(/^\D+/g, "") : null;
      const partyPolarity = normalizeString(party?.Polarity);
      if (partyDoc === matchKeys && partyPolarity === "passive") {
        return true;
      }
    }
    return false;
  };

  // Cria um objeto para armazenar totais por Status
  const statusTotals = {};

  // Função para normalizar Status
  const normalizeStatus = (status) => (status ? status.toLowerCase() : "");

  // Filtra e soma valores somente quando a Polarity for 'Passive' e o Doc corresponde ao MatchKeys
  sortedLawsuits.forEach((lawsuit) => {
    if (findMatchingDocAndPolarity(lawsuit?.Parties || [])) {
      const lawsuitPolarity = normalizeString(
        lawsuit?.Parties?.find(
          (party) => party?.Doc && party?.Doc.replace(/^\D+/g, "") === matchKeys
        )?.Polarity
      );

      if (lawsuitPolarity === "passive") {
        const lawsuitStatus = normalizeStatus(lawsuit?.Status);
        const lawsuitValue = lawsuit.Value;

        if (lawsuitValue === -1) {
          // Garante que o status é incluído no statusTotals, mas com valor null
          if (!statusTotals[lawsuitStatus]) {
            statusTotals[lawsuitStatus] = null;
          }
        } else {
          // Converte valores negativos para 0 e adiciona ao statusTotal
          const valueToAdd = Math.max(lawsuitValue, 0);
          if (statusTotals[lawsuitStatus]) {
            statusTotals[lawsuitStatus] += valueToAdd;
          } else {
            statusTotals[lawsuitStatus] = valueToAdd;
          }
        }
      }
    }
  });

  // Total geral de Passive
  const totalValue = sortedLawsuits
    .filter((lawsuit) => findMatchingDocAndPolarity(lawsuit?.Parties || []))
    .reduce((total, lawsuit) => {
      const lawsuitPolarity = normalizeString(
        lawsuit?.Parties?.find(
          (party) => party?.Doc && party?.Doc.replace(/^\D+/g, "") === matchKeys
        )?.Polarity
      );
      const value = lawsuit.Value;
      // Adiciona o valor ao total apenas se for não negativo e a polaridade for "passive"
      return total + (lawsuitPolarity === "passive" && value >= 0 ? value : 0);
    }, 0);

  // Extrai dados dos lawsuits dos sócios
  const lawsuitsDataSocio =
    processosSocioData?.Result?.[0]?.OwnersLawsuits?.Lawsuits || {};

  // Extrai todos os lawsuits e mantém o Doc associado
  const allLawsuitsSocio = Object.entries(lawsuitsDataSocio).flatMap(
    ([doc, owner]) =>
      owner.Lawsuits?.flatMap((lawsuit) => ({
        ...lawsuit,
        Doc: doc,
        Parties: lawsuit.Parties || [], // Garante que Parties sempre seja um array
      })) || []
  );

  const sortedSocioLawsuits = Array.isArray(allLawsuitsSocio)
    ? allLawsuitsSocio.sort(
        (a, b) => new Date(b.LastUpdate) - new Date(a.LastUpdate)
      )
    : [];

  // Cria um objeto para armazenar totais por Status
  const statusTotalsSocio = {};

  // Verifica o Doc e a Polarity em todos os objetos de Parties
  const findMatchingDocAndPolaritySocio = (parties, doc) => {
    for (const party of parties) {
      const partyDoc = party?.Doc ? party.Doc.replace(/^\D+/g, "") : null;
      const partyPolarity = party?.Polarity
        ? normalizeString(party.Polarity)
        : null;
      if (partyDoc === doc && partyPolarity === "passive") {
        return true;
      }
    }
    return false;
  };

  // Filtra e soma valores somente quando a Polarity for 'Passive' e o Doc corresponde ao Doc dos Lawsuits
  sortedSocioLawsuits.forEach((lawsuit) => {
    const parties = lawsuit?.Parties || [];
    const doc = lawsuit?.Doc ? lawsuit.Doc.replace(/^\D+/g, "") : null;
    if (findMatchingDocAndPolaritySocio(parties, doc)) {
      const lawsuitPolarity = normalizeString(
        parties.find((party) => party?.Doc?.replace(/^\D+/g, "") === doc)
          ?.Polarity
      );
      if (lawsuitPolarity === "passive") {
        const lawsuitStatus = normalizeStatus(lawsuit?.Status);
        const valueToAdd = Math.max(lawsuit.Value, 0); // Converte valores negativos para 0
        if (statusTotalsSocio[lawsuitStatus]) {
          statusTotalsSocio[lawsuitStatus] += valueToAdd;
        } else {
          statusTotalsSocio[lawsuitStatus] = valueToAdd;
        }
      }
    }
  });

  // Total geral de Passive
  const totalSocioValue = sortedSocioLawsuits
    .filter((lawsuit) => {
      const parties = lawsuit?.Parties || [];
      const doc = lawsuit?.Doc ? lawsuit.Doc.replace(/^\D+/g, "") : null;
      return findMatchingDocAndPolaritySocio(parties, doc);
    })
    .reduce((total, lawsuit) => {
      const parties = lawsuit?.Parties || [];
      const lawsuitDoc = lawsuit?.Doc ? lawsuit.Doc.replace(/^\D+/g, "") : null;
      const lawsuitPolarity = parties.find(
        (party) => party?.Doc?.replace(/^\D+/g, "") === lawsuitDoc
      )?.Polarity;
      const value = lawsuit.Value;
      // Adiciona o valor ao total apenas se for não negativo e a polaridade for "passive"
      return (
        total +
        (normalizeString(lawsuitPolarity) === "passive" && value >= 0
          ? value
          : 0)
      );
    }, 0);

  // Determina a faixa de renda a ser exibida
  const faixaRenda = () => {
    // Verifica se a Faixa de Renda está disponível no primeiro conjunto de dados
    if (
      atividadeData &&
      atividadeData.Result &&
      atividadeData.Result[0] &&
      atividadeData.Result[0].ActivityIndicators &&
      atividadeData.Result[0].ActivityIndicators.IncomeRange
    ) {
      const incomeRange =
        atividadeData.Result[0].ActivityIndicators.IncomeRange;
      if (
        incomeRange &&
        incomeRange.trim() !== "" &&
        incomeRange !== "SEM INFORMACAO"
      ) {
        return incomeRange;
      }
    }

    // Se não estiver disponível ou se for "SEM INFORMACAO", verifica no segundo conjunto de dados
    if (
      evolucaoData &&
      evolucaoData.Result &&
      evolucaoData.Result[0] &&
      evolucaoData.Result[0].CompanyEvolutionData &&
      evolucaoData.Result[0].CompanyEvolutionData.DataHistoryOverTime &&
      evolucaoData.Result[0].CompanyEvolutionData.DataHistoryOverTime.length > 0
    ) {
      const incomeRangeFromHistory =
        evolucaoData.Result[0].CompanyEvolutionData.DataHistoryOverTime[0]
          .IncomeRange;
      if (
        incomeRangeFromHistory &&
        incomeRangeFromHistory.trim() !== "" &&
        incomeRangeFromHistory !== "SEM INFORMACAO"
      ) {
        return incomeRangeFromHistory;
      }
    }

    return "SEM INFORMAÇÃO";
  };

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
          <p className="text-black ml-4">Carregando dados...</p>
        </div>
      )}
      {!loading && submitted && error && (
        <p className="text-red-600">Erro: {error}</p>
      )}
      {!loading && submitted && displayData?.Result?.[0]?.BasicData ? (
        <div className="table-responsive max-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white">
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nome Oficial
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.OfficialName ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  CNPJ
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatCNPJ(displayData.Result[0].BasicData.TaxIdNumber) ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Score
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {scoreData?.Result?.[0]?.QUODCreditScoreCompany?.Score ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Está em Cobrança?
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {presCobData?.Result?.[0]?.Collections
                    ?.IsCurrentlyOnCollection !== undefined
                    ? presCobData.Result[0].Collections.IsCurrentlyOnCollection
                      ? "Sim"
                      : "Não"
                    : "Não encontrado"}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Idade
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.Age || "Não encontrado"}
                </td>
              </tr>
              {evolucaoData?.Result?.[0]?.CompanyEvolutionData
                ?.DataHistoryOverTime?.length > 0 ? (
                <>
                  <tr className="border-b border-gray-200">
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Capital
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {formatarCapital(
                        displayData.Result[0].BasicData.AdditionalOutputData
                          .CapitalRS
                      ) || "Não encontrado"}
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200">
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Faixa de Renda
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {faixaRenda() || "Não encontrado"}
                    </td>
                  </tr>
                  {processosEmpresaData?.Result?.[0]?.Lawsuits ? (
                    <>
                      <tr>
                        <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Valor Passivo Processo Empresa
                        </th>
                        <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                          {formatarCapital(totalValue) || "Sem Informação"}
                        </td>
                      </tr>
                      {Object.keys(statusTotals).map((status) => (
                        <tr key={status}>
                          <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {status}
                          </th>
                          <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                            {statusTotals[status] !== null
                              ? formatarCapital(statusTotals[status])
                              : "Sem Informação"}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Valor Passivo Processo Empresa
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        Sem Informação
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Capital
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    Não encontrado
                  </td>
                </tr>
              )}
              {processosSocioData?.Result?.[0]?.OwnersLawsuits ? (
                <>
                  <tr className="border-t border-gray-200">
                    <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valor Passivo Processo Sócio
                    </th>
                    <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                      {formatarCapital(totalSocioValue) || "Sem Informação"}
                    </td>
                  </tr>
                  {Object.keys(statusTotalsSocio).map((status) => (
                    <tr key={status}>
                      <th className="px-7 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {status}
                      </th>
                      <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {formatarCapital(statusTotalsSocio[status]) ||
                          "Sem Informação"}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Valor Passivo Processo Sócio
                  </th>
                  <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                    Sem Informação
                  </td>
                </tr>
              )}
              <tr className="border-b border-t border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Situação Cadastral
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {displayData.Result[0].BasicData.TaxIdStatus ||
                    "Não encontrado"}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  NUM RECUPERAÇÃO JUDICIAL
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {historyData?.Result?.[0]?.HistoryBasicData?.NameHistoryList?.reduce(
                    (count, item) =>
                      item.Name.includes("RECUPERACAO JUDICIAL")
                        ? count + 1
                        : count,
                    0
                  ) ?? 0}
                </td>
              </tr>
              <tr className="border-b border-gray-200">
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Dívida Governo
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {dividasData?.Result?.[0]?.GovernmentDebtors
                    ?.TotalDebtValue !== undefined
                    ? formatarCapital(
                        dividasData.Result[0].GovernmentDebtors.TotalDebtValue
                      )
                    : "Não encontrado"}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantidade Protestos
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {protestosData?.[0]?.cartorios
                    ? Object.values(protestosData[0].cartorios).reduce(
                        (total, estado) => {
                          return (
                            total +
                            Object.values(estado).reduce(
                              (subtotal, cartorio) =>
                                subtotal +
                                (cartorio.quantidade ? cartorio.quantidade : 0),
                              0
                            )
                          );
                        },
                        0
                      )
                    : 0}
                </td>
              </tr>
              <tr>
                <th className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valor Total Protestos
                </th>
                <td className="px-3 py-1 font-semibold text-sm text-gray-800">
                  {formatarCapital(
                    protestosData?.[0]?.cartorios
                      ? Object.values(protestosData[0].cartorios).reduce(
                          (total, estado) => {
                            return (
                              total +
                              Object.values(estado).reduce(
                                (subtotal, cartorio) =>
                                  subtotal +
                                  (cartorio.protestos
                                    ? cartorio.protestos.reduce(
                                        (protestoTotal, protesto) =>
                                          protestoTotal +
                                          (protesto.valor ? protesto.valor : 0),
                                        0
                                      )
                                    : 0),
                                0
                              )
                            );
                          },
                          0
                        )
                      : 0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        !loading &&
        submitted &&
        !error && (
          <p>
            Não foram encontrados dados para renderizar. Por favor, verifique a
            resposta da API.
          </p>
        )
      )}
    </div>
  );
}

export default DadosScore;
