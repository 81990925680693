import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import scorePFService from "../services/scorePFService"; // Importe o serviço atualizado

const initialState = {
  presCobPF: [],
  probNegPF: [],
  riscoFinanceiroPF: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getPresCob = createAsyncThunk(
  "scorepf/getprescob",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await scorePFService.getPresCob(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProbNeg = createAsyncThunk(
  "scorepf/getprobneg",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await scorePFService.getProbNeg(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getRiscoFinanceiro = createAsyncThunk(
  "scorepf/getriscofinanceiro",
  async ({ cpf }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await scorePFService.getRiscoFinanceiro(cpf);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const scorePFSlice = createSlice({
  name: "scorepf",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresCob.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getPresCob.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.presCobPF = action.payload;
      })
      .addCase(getPresCob.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProbNeg.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProbNeg.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.probNegPF = action.payload;
      })
      .addCase(getProbNeg.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getRiscoFinanceiro.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getRiscoFinanceiro.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.riscoFinanceiroPF = action.payload;
      })
      .addCase(getRiscoFinanceiro.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = scorePFSlice.actions;
export default scorePFSlice.reducer;
