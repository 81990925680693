import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard2 } from "../../slices/dashboardSlice";

const DashboardTable = ({ dataSelecionada, scoreNumero }) => {
  const { dashboards2 } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [isMwhDisplayed, setIsMwhDisplayed] = useState(true);

  // Estado para os tipos de contraparte
  const [counterpartyTypes, setCounterpartyTypes] = useState([]);
  const [selectedCounterpartyTypes, setSelectedCounterpartyTypes] = useState([]);

  useEffect(() => {
    if (dataSelecionada) {
      dispatch(getDashboard2({ data1: dataSelecionada, score: scoreNumero }));
    }
  }, [dispatch, dataSelecionada, scoreNumero]);

  useEffect(() => {
    if (dashboards2 && dashboards2.length > 0) {
      // Extrair os valores únicos de counterpartyType
      const uniqueCounterpartyTypes = [...new Set(dashboards2.map(d => d.counterpartyType))];
      setCounterpartyTypes(uniqueCounterpartyTypes);
      setSelectedCounterpartyTypes(uniqueCounterpartyTypes); // Seleciona todos por padrão
    }
  }, [dashboards2]);

  const handleCounterpartyTypeChange = (type) => {
    if (selectedCounterpartyTypes.includes(type)) {
      setSelectedCounterpartyTypes(prev => prev.filter(t => t !== type));
    } else {
      setSelectedCounterpartyTypes(prev => [...prev, type]);
    }
  };

  if (!dashboards2 || dashboards2.length === 0) {
    return <p className="text-black">Carregando dados do Dashboard...</p>;
  }

  const vectorSequence = ["M0", "M1", "M2", "T1", "Y0", "Y1", "> Y1", "Total"];

  const filteredData = dashboards2.filter(d =>
    selectedCounterpartyTypes.includes(d.counterpartyType)
  );

  const groupedData = filteredData.reduce((acc, dashboard) => {
    let { counterpartyName, vector, Mtm, MWh, MWm, score } = dashboard;

    if (!acc[counterpartyName]) {
      acc[counterpartyName] = { score, vectors: {} };
    }

    if (!acc[counterpartyName].vectors[vector]) {
      acc[counterpartyName].vectors[vector] = { Mtm: 0, MWh: 0, MWm: 0 };
    }

    acc[counterpartyName].vectors[vector].Mtm += Mtm;
    acc[counterpartyName].vectors[vector].MWh += MWh;
    acc[counterpartyName].vectors[vector].MWm += MWm;

    return acc;
  }, {});

  const getCellStyle = (value, textAlign = "center") => ({
    padding: "4px",
    textAlign,
    minWidth: "60px",
    color: value < 0 ? "red" : "black",
    height: "25px",
  });

  const formatValue = (value) => {
    const formattedValue = (value / 1000).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const parts = formattedValue.split(",");
    return parts[0].replace(",", ".") + "," + parts[1] + "K";
  };

  const tableContainerStyle = {
    marginTop: "20px",
    display: "flex",
    width: "100%",
    overflowX: "auto",
  };

  const tableStyle = {
    fontSize: "0.6rem",
    borderCollapse: "separate",
    borderSpacing: "0",
    width: "100%",
    borderRadius: "20px",
    overflow: "hidden",
  };

  const thStyle = {
    padding: "4px",
    minWidth: "60px",
    fontSize: "0.7rem"
  };

  const sortedCounterparties = Object.keys(groupedData).sort((a, b) => {
    const totalA = groupedData[a].vectors["Total"]?.Mtm || 0;
    const totalB = groupedData[b].vectors["Total"]?.Mtm || 0;
    return totalB - totalA;
  });

  const handlePageChange = (newPage) => {
    if (
      newPage >= 1 &&
      newPage <= Math.ceil(sortedCounterparties.length / rowsPerPage)
    ) {
      setCurrentPage(newPage);
    }
  };

  const paginatedCounterparties = sortedCounterparties.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const safeNumber = (value) => (typeof value === "number" ? value : 0);

  return (
    <div className="w-full px-5">
      <h1 className="text-black text-center text-2xl pt-5 pb-5">
        Tabela Risco Contraparte
      </h1>
      <div className="flex items-center justify-center mb-5">
        <div className="flex flex-wrap">
          {counterpartyTypes.map((type) => (
            <label key={type} className="inline-flex items-center mr-4 mb-2">
              <input
                type="checkbox"
                value={type}
                checked={selectedCounterpartyTypes.includes(type)}
                onChange={() => handleCounterpartyTypeChange(type)}
                className="form-checkbox h-5 w-5 text-blue-500"
              />
              <span className="ml-2 text-gray-700">{type}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="table-container" style={tableContainerStyle}>
        <div className="table-wrapper" style={{ flex: 1 }}>
          <table style={tableStyle}>
            <thead>
              <tr className="bg-gray-500">
                <th style={{...thStyle, fontSize: "0.9rem"}} colSpan={vectorSequence.length + 2} className="border-r-2 border-black">
                  MtM
                </th>
                <th style={{...thStyle, fontSize: "0.9rem"}} colSpan={vectorSequence.length + 1}>
                  {isMwhDisplayed ? "MWh" : "MWm"}
                  <button
                    onClick={() => setIsMwhDisplayed(!isMwhDisplayed)}
                    className="ml-2 text-white focus:outline-none"
                  >
                    {isMwhDisplayed ? "\u2192 MWm" : "\u2190 MWh"}
                  </button>
                </th>
              </tr>
              <tr className="bg-gray-200">
                <th style={{ ...thStyle, textAlign: "left" }}>SCORE</th>
                <th style={{ ...thStyle, textAlign: "left" }}>CONTRAPARTE</th>
                {vectorSequence.map((vector, index) => {
                  const isLastColumn = index === vectorSequence.length - 1;
                  return (
                    <th
                      key={index}
                      style={{
                        ...thStyle,
                        borderRight: isLastColumn ? "2px solid black" : "none",
                      }}
                    >
                      {vector}
                    </th>
                  );
                })}
                {vectorSequence.map((vector, index) => (
                  <th key={index} style={thStyle} className="bg-gray-200">
                    {vector}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedCounterparties.map((counterpartyName, index) => {
                const { score, vectors } = groupedData[counterpartyName];

                return (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}
                  >
                    <td
                      style={{ ...getCellStyle(0), textAlign: "left" }}
                      className="font-bold"
                    >
                      {score}
                    </td>
                    <td
                      style={{ ...getCellStyle(0), textAlign: "left" }}
                      className="font-bold"
                    >
                      {counterpartyName}
                    </td>
                    {vectorSequence.map((vector, subIndex) => {
                      const value = safeNumber(vectors[vector]?.Mtm);

                      // Verifique se é a última coluna
                      const isLastColumn =
                        subIndex === vectorSequence.length - 1;

                      return (
                        <td
                          key={subIndex}
                          style={{
                            ...getCellStyle(value),
                            borderRight: isLastColumn
                              ? "2px solid black"
                              : "none", // Adiciona borda na última coluna
                          }}
                        >
                          {value !== 0 ? formatValue(value) : ""}
                        </td>
                      );
                    })}

                    {vectorSequence.map((vector, subIndex) => {
                      const value = isMwhDisplayed
                        ? safeNumber(vectors[vector]?.MWh)
                        : safeNumber(vectors[vector]?.MWm);

                      const displayValue = isMwhDisplayed
                        ? formatValue(value)
                        : value.toFixed(2);

                      return (
                        <td key={subIndex} style={getCellStyle(value)}>
                          {value !== 0 ? displayValue : ""}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-end items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="mx-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
        >
          &lt;
        </button>
        <span>
          Página {currentPage} de{" "}
          {Math.ceil(sortedCounterparties.length / rowsPerPage)}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(sortedCounterparties.length / rowsPerPage)
          }
          className="mx-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default DashboardTable;
