// CNPJForm.js
import React from "react";

function CPFForm({ cpf, onChange, onSubmit, onSearch }) {
  return (
    <div className="flex items-center gap-2">
      <input
        type="submit"
        value="Nova Consulta"
        onClick={onSubmit}
        className="bg-blue-500 text-white p-2 cursor-pointer"
      />
      <input
        type="text"
        name="cpf"
        value={cpf}
        onChange={onChange}
        className="border p-2"
        maxLength={14} // 11 dígitos + 3 caracteres de formatação
      />
      <input
        type="submit"
        value="Histórico"
        onClick={onSearch}
        className="bg-blue-500 text-white p-2 cursor-pointer"
      />
    </div>
  );
}

export default CPFForm;
