import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import protestosPJService from "../services/protestosPJService"; // Importe o serviço atualizado

const initialState = {
  protestosPJ: [],
  protestosSPPJ: [],
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getProtestosPJ = createAsyncThunk(
  "protestospj/getprotestospj",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await protestosPJService.getProtestosPJ(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getProtestosSPPJ = createAsyncThunk(
  "protestospj/getprotestossppj",
  async ({ cnpj }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await protestosPJService.getProtestosSPPJ(cnpj);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const protestosPJSlice = createSlice({
  name: "protestospj",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProtestosPJ.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProtestosPJ.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.protestosPJ = action.payload;
      })
      .addCase(getProtestosPJ.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getProtestosSPPJ.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProtestosSPPJ.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.protestosSPPJ = action.payload;
      })
      .addCase(getProtestosSPPJ.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const { resetMessage } = protestosPJSlice.actions;
export default protestosPJSlice.reducer;
