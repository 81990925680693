import React from "react";

const Monitoring = () => {

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 gap-5">
        <h1 className="text-3xl font-bold text-white">Monitoring</h1>
        
      </div>
    </div>
  );
};

export default Monitoring;
