import React, { useEffect, useState } from "react";
import DistProcessos from "./DistProcessos";
import DistProcessosF from "./DistProcessosF";
import ProcessosJA from "./ProcessosJA";
import ProcessosJAF from "./ProcessosJAF";
import NavegacaoPF from "./NavegacaoPF";
import { getDistProcessosFamily } from "../slices/processosPFSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CPFForm from "./CPFForm";

function PFProcessos() {
  const [cpf, setCpf] = useState("");
  const [submittedCPF, setSubmittedCPF] = useState("");
  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const { distProcessosFamilyPF } = useSelector((state) => state.processosPF); // Substitua pelo seu selector quando disponível

  // Função para formatar o CPF
  const formatCPF = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove qualquer caractere não numérico
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/); // Divide o CPF em grupos de dígitos
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`; // Formata o CPF
    }
    return cleaned; // Retorna o valor limpo se não corresponder ao formato
  };

  // Função para extrair apenas números
  const getOnlyNumbers = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "").slice(0, 14);
    setCpf(formatCPF(value)); // Atualiza o CNPJ no contexto
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cpfNumbers = getOnlyNumbers(cpf);
    setSubmittedCPF(cpfNumbers);
    setSubmit(true);
    setSearch(false);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSubmittedCPF(getOnlyNumbers(cpf));
    setSearch(true);
    setSubmit(false);
  };

  useEffect(() => {
    if (submit || search) {
      const fetchData = async () => {
        try {
          if (search) {
            await dispatch(getDistProcessosFamily({ cpf: submittedCPF }));
          } else {
            const result = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/dist_processos_family`,
              { cpf: submittedCPF }
            );
            setData(result.data);
          }
        } catch (error) {
          console.error("Erro ao buscar dados:", error);
        }
        setSubmit(false);
      };

      fetchData();
    }
  }, [submit, search, submittedCPF, dispatch]);

  const displayData = search ? distProcessosFamilyPF : data;

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-full h-full px-3 md:px-0">
        <h1 className="text-xl font-bold text-white">
          Pessoa Física - Processos
        </h1>
        <p className="text-gray-300 pb-2">
          Insira o CPF para obter os Processos
        </p>
        <CPFForm
          cpf={cpf}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onSearch={handleSearch}
        />
        <div className="flex justify-end w-full">
          <NavegacaoPF />
        </div>
        <div className="shadow-lg rounded-lg overflow-hidden mx-3 md:mx-4">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  Distribuição Processos
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase flex justify-between whitespace-nowrap">
                  Distribuição Processos Familiares
                  {displayData && (
                    <div>
                      <span className="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Data da Consulta
                      </span>
                      <span className="px-3 py-1 font-semibold text-sm text-gray-800">
                        {new Date(displayData.QueryDate).toLocaleDateString(
                          "pt-BR"
                        )}
                      </span>
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <DistProcessos
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <DistProcessosF
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  Processos Judiciais e Administrativos
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-bold uppercase">
                  Processos Judiciais e Administrativos Familiares
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-1 align-top">
                  <ProcessosJA
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
                <td className="border-b border-gray-200 p-1 align-top">
                  <ProcessosJAF
                    cpf={submittedCPF}
                    submit={submit}
                    setSubmit={setSubmit}
                    search={search}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="flex mx-3 md:mx-4 mt-5">
        <Link
          to="/pessoa-fisica/score"
          className="bg-gray-100 text-gray-600 p-2 rounded-lg w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
        >
          Score
        </Link>
        <Link
          to="/pessoa-fisica/processos"
          className="bg-gray-100 text-gray-600 p-2 rounded-lg ml-4 w-[250px] text-center transition-colors duration-300 hover:bg-black hover:text-gray-100 font-bold"
        >
          Dados Essenciais
        </Link>
      </div> */}
    </div>
  );
}

export default PFProcessos;
