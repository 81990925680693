import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "../services/dashboardService"; // Importe o serviço atualizado

const initialState = {
  dashboards: [],
  dashboards2: [],
  dashboard: {},
  error: false,
  success: false,
  loading: false,
  message: null,
};

export const getDashboard = createAsyncThunk(
  "dashboard/getdashboard",
  async ({ data1, data2, operationType }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");

    const data = await dashboardService.getDashboard(data1, data2, operationType);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const getDashboard2 = createAsyncThunk(
  "dashboard/getdashboard2",
  async ({ data1, score }, thunkAPI) => {
    console.log("Enviando solicitação para a API com os seguintes parâmetros:");
    console.log("data:", data1);

    const data = await dashboardService.getDashboard2(data1, score);

    console.log("Dados recebidos da API:");
    console.log(data);

    return data;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dashboards = action.payload;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(getDashboard2.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDashboard2.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.dashboards2 = action.payload;
      })
      .addCase(getDashboard2.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
      });
  },
});

export const { resetMessage } = dashboardSlice.actions;
export default dashboardSlice.reducer;
